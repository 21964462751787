<template>
  <div id="app">
    <transition name="slide-fade">
      <div v-if="isLoggedIn && isOpen">
      <div class="overlay"></div>
        <gro-outside-click :do="close">
          <div id="sidebar">
            <div class="nav-menu">
              <a
                  class="nav-menu-item"
                  v-for="(i, index) in $router.options.routes"
                  v-show="!i.meta.hideNav"
                  :key="`route-${index}`"
                  @click="goTo(i.name)"
              >
                  <div class="nav-menu-icon">
                    <i class="material-icons material-icons-filled">{{ i.meta.icon }}</i>
                  </div>
                  <div class="nav-menu-name">
                  <span>{{ i.name }}</span>
                  </div>
              </a>
              <div class="nav-menu-item" @click="logout()">
                <div class="nav-menu-icon">
                  <i class="material-icons material-icons-filled">logout</i>
                </div>
                <div class="nav-menu-name">
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        </gro-outside-click>
      </div>
    </transition>
    <div id="app-header" v-if="isLoggedIn">
      <div class="header-inner">
        <i class="material-icons" @click="clickMenu()">{{ isOpen ? 'menu_open' : 'menu'  }}</i>
        <img src="@/assets/LCP-Logo-Blue.svg" width="200" alt="Low Carb Program">
      </div>
      <div class="provider-select" v-if="providersList">
        <v-select
                v-tooltip="`View a different cohort`"
                :options="providersList"
                label="name"
                v-model="provider"
                :getOptionLabel="provider => provider.name"
                placeholder="Search"
                ref="provider"
        >
          <template #search="{ attributes, events }">
            <svg id="search-icon" v-if="!provider" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            <input class="vs__search" v-bind="attributes" v-on="events">
          </template>
          <template #selected-option="{ name }">
            <div style="display: flex; align-items: flex-end;">
              <img src="@/assets/group_work_white_24dp.svg" style="margin-right: 8px;" alt="Cohort Icon"/>
              {{name}}
            </div>
          </template>
          <template #list-header v-if="recentProviders.length >= 1">
            <li class="recentTitle">Recent</li>
            <div v-for="(item, index) in recentProviders">
              <li class="recentProvider" style="text-align: left" @click="setRecent(item)">{{item.name}}</li>
            </div>
            <div style="border-bottom: 2px solid rgb(190, 201, 213);width: 90%;margin: 5px auto 0"></div>
          </template>
        </v-select>
      </div>
    </div>
    <router-view @click="isOpen = false"></router-view>
  </div>
</template>

<script>

import _ from "lodash";
import AuthenticationManager from "@/helpers/AuthenticationManager";
import axios from "axios";
import config from '@/config'
import LoginApi from "@/api/Login";
import router from "@/router";
import AnalyticsApi from "@/api/Analytics";
import 'vue-select/dist/vue-select.css';

export default {
  name: 'App',
  data() {
    return {
      item: '',
      isOpen: false,
      providersList: null,
      provider: this.$store.getters.storedProvider ? this.$store.getters.storedProvider : null,
      recentProviders: [],
    }
  },
  watch: {
    $route: function() {
      if (!this.providersList && this.$store.getters.isLoggedIn) {
        this.getProvider();
      }
    },
    provider: function() {
      if (this.provider) {
        if (!this.recentProviders.some(item => item.id === this.provider.id)) {
            this.recentProviders.push(this.provider)
        }
        this.$store.commit('set_provider', this.provider)
      }
    }
  },
  computed: {
    isLoggedIn: function() { return this.$store.getters.isLoggedIn },
  },
  mounted() {
    let self = this;
    if (AuthenticationManager.hasToken()) {
      self.$store.commit('logged_in', true);
      self.provider = null
      const tokenObject = AuthenticationManager.getToken();
      if (tokenObject.expires < new Date()) {
        AuthenticationManager.refreshAccessToken(tokenObject);
        self.getProvider();
      } else {
        AuthenticationManager.setToken(tokenObject);
        self.getProvider();
      }
    }
  },
  methods: {
    setRecent(provider) {
      if (provider) {
        if (!this.recentProviders.some(item => item.id === provider.id)) {
          this.recentProviders.push(provider)
        }
        this.provider = provider;
        this.$store.commit('set_provider', provider)
        this.$refs.provider.searchEl.blur()
      }
    },
    goTo(routeTo) {
      if (this.$route.name !== routeTo) {
        router.push({
          name: routeTo
        })
        this.isOpen = false
      }
    },
    getProvider() {
      let self = this;
      self.providersList = null;
      AnalyticsApi.getProviderList()
        .then(function(response) {
          self.providersList = response.data;
        })
    },
    clickMenu() {
      setTimeout(() => this.isOpen = true, 1)
    },
    close() {
      this.isOpen = false
    },
    logout() {
      let self = this;
      axios.get(config.lcpAPI + 'logout/')
        .then(function () {
          if (_.has(axios.defaults.headers.common, 'Authorization')) {
            delete axios.defaults.headers.common['Authorization'];
          }
          self.$store.commit('logged_in', false);
          self.$store.commit('set_provider', '')
          self.provider = null;
          self.recentProviders = []
          self.providersList = null;
          self.$store.commit('set_user', '')
          AuthenticationManager.clearSession();
          sessionStorage.clear();
          router.push({
            name: 'Login'
          })
        });
    }
  },
}
</script>

<style lang="scss">

  #search-icon {
    width: 16px;
    height: 16px;
    color: #999;
    margin: 8px 0px 0 8px;
    order: 0;
  }

  .v-select .vs__selected {
    order: 1;
    left: 10%;
  }

  .v-select .vs__search {
    order: 2;
  }
  .recentProvider, .vs__dropdown-option {
    clear: both;
    color: var(--vs-dropdown-option-color);
    cursor: pointer;
    display: block;
    line-height: 1.42857143;
    padding: var(--vs-dropdown-option-padding);
    white-space: nowrap;
    font-weight: 500;
    font-family: $poppins;
    &.vs__dropdown-option--highlight {
      background: var(--vs-dropdown-option--active-bg);
      color: var(--vs-dropdown-option--active-color);
    }
    &:hover {
      background: var(--vs-dropdown-option--active-bg);
      color: var(--vs-dropdown-option--active-color)!important;
    }
  }

  .v-select {
    border-color: #0179d5;
    border-width: 1px;
    width: 280px
  }

  .slide-fade-enter-active {
    @include transition(all, .2s, ease);
  }
  .slide-fade-leave-active {
    @include transition(all, .2s, cubic-bezier(1.0, 0.5, 0.8, 1.0));
  }
  .slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
    @include transform(translateX(-10px));
  }

  html, body {
    height: 100%;
    overflow: auto;
  }
  #app {
    height: 100%;
    .overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 30%);
      z-index: 99;
    }
    #sidebar {
      position: absolute;
      top: 0;
      left: 0;
      width: 233px;
      height: 100%;
      z-index: 99;
      .nav-menu {
        position: absolute;
        height: 100%;
        background: $white;
        width: 233px;
        @include box-shadow(0 1px 24px 0 rgba(91, 97, 124, 0.2));
        .nav-menu-item {
          @include flexbox();
          @include flexbox-justify-content(flex-start);
          @include flexbox-align-items(center);
          @include transition(all, .2s, ease-in-out);
          padding: 10px 0 10px 24px;
          margin: 5px 0;
          cursor: pointer;
          text-decoration: none;
          &:hover {
            background: rgb(0 0 0 / 10%);
          }
          .nav-menu-name {
            font-family: $poppins;
            font-weight: 400;
            font-size: 1rem;
            color: $black;
          }
          .nav-menu-icon {
            color: $theme-color;
            margin-right: 8px;
            i {
              vertical-align: middle;
            }
          }
        }
      }
    }
    //header
    #app-header {
      height: 90px;
      margin: 0 0 36px;
      padding-left: 150px;
      box-shadow: 0 1px 16px 0 rgba(91, 97, 124, 0.2);
      @include flexbox();
      @include flexbox-justify-content(space-between);
      @include flexbox-align-items(center);
      @include responsive-below(750px) {
        padding-left: 15px;
      }
      .header-inner {
        max-width: 700px;
        @include flexbox();
        @include flexbox-align-items(center);
        img {
          @include responsive-below(750px) {
            display: none;
          }
        }
        i {
          color: $theme-color;
          font-size: 24px;
          @include transition(all, 0.2s, ease-in-out);
          margin: 0 47px 0 0;
          cursor: pointer;
        }
      }
      .provider-select {
        margin-right: 180px;
        @include responsive-below(750px) {
          margin-right: 15px;
        }
        .recentTitle {
          color: #5b617c;
          font-size: 14px;
          padding: 5px 20px 5px;
        }
      }
    }
  }
  .tooltip {
    .tooltip-inner {
      background: #051f33!important;
      padding: 6px 10px 6px!important;
      font-family: $poppins;
      font-weight: normal;
      font-size: .8rem;
      opacity: .9;
    }
    &[x-placement^="top"] {
      .tooltip-arrow {
        border-color: #051f33!important;
      }
    }
  }
</style>
