<template>
  <form class="search-bar" @submit.prevent="onSearch">
    <gro-input id="search_patients" name="search_patients" placeholder="Search by email" v-model="searchTerm" @click="onSearch()">
      <div slot="leftIcon" class="d-flex">
        <i class="material-icons">search</i>
      </div>
    </gro-input>
    <input type="submit" style="display: none;" @click="onSearch()" />
  </form>
</template>

<script>
  export default {
    name: "SearchBar",
    data() {
      return {
        searchTerm: this.$store.getters.searchTerm ? this.$store.getters.searchTerm : ''
      }
    },
    methods: {
     async onSearch() {
        let self = this;
        await self.$store.commit('search_patients', { searchTerm: self.searchTerm});
        // eslint-disable-next-line no-unused-vars
        self.$router.push({ name: 'PatientsSearch', params: { searchTerm: self.searchTerm }}).catch(err => {})
      },
    }
  }
</script>

<style scoped>
  .gro__input {
    width: 400px;
  }
</style>