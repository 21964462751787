<template>
  <div id="patient-details">
    <div class="p-5 text-center" v-if="patientIsLoading">
      <gro-spinner color="primary" size="lg"></gro-spinner>
    </div>
    <div v-if="!patientIsLoading" class="patient-details-inner container">
      <div class="row">
        <div v-if="patientInfo" class="patient-details-info col-sm-12 col-md-12 col-lg-8">
          <gro-breadcrum>
            <gro-breadcrum-item
                :to="{path: '/dashboard'}"
                title="Dashboard"
                icon="icon-home">
            </gro-breadcrum-item>
            <gro-breadcrum-item
                :to="{path: '/patients'}"
                title="My patients"
                icon="arrow_right"
                material>
            </gro-breadcrum-item>
            <gro-breadcrum-item
                :title="`Detail: ${patientInfo.firstName} ${patientInfo.lastName}`"
                icon="arrow_right"
                material>
            </gro-breadcrum-item>
          </gro-breadcrum>
          <div class="patient-details-title">
            <h1>Detail: {{ patientInfo.firstName + ' ' + patientInfo.lastName }}</h1>
            <h2><strong>Treatment type</strong>: {{stringMedications}}</h2>
          </div>
          <gro-card shadow :bordered="true">
            <div slot="body">
              <div class="mb-2">
                <div class="container">
                  <div class="row">
                    <div class="col-2">
                      <img :src="patientInfo.profilePictureURL ? patientInfo.profilePictureURL : 'https://www.lowcarbprogram.com/assets/avatar.png'"  class="gro__card__avatar" alt="">
                    </div>
                    <div class="col-10">
                      <div class="text-primary gro__card__info__title">{{ patientInfo.firstName + ' ' + patientInfo.lastName }} <i class="material-icons material-icons-filled" v-tooltip="`Lesson progress: ${patientInfo.progress}%`">check_circle</i></div>
                      <div class="gro__card__label__user_id">User {{patientInfo.userId}}</div>
                      <div class="gro__card__label__diabetes__type">{{ patientInfo.diabetesType }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="divider"></div>
              <div class="mb-2">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <div class="mb-2">
                        <div class="gro__card__label">Location</div>
                        <div class="gro__card__label__info isBlue"><span class="flag-icon flag-icon-gb"></span>{{patientInfo.location}}</div>
                      </div>
                      <div class="gro__card__label">Ethnicity</div>
                      <div class="gro__card__label__info">{{ patientInfo.ethnicity }}</div>
                    </div>
                    <div class="col-6">
                      <div class="mb-2">
                        <div class="gro__card__label">Date of birth</div>
                        <div class="gro__card__label__info">{{ formatDate(patientInfo.dateOfBirth) }}</div>
                      </div>
                      <div class="gro__card__label">Email address</div>
                      <div class="gro__card__label__info">{{ patientInfo.email }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="divider"></div>
              <div class="mb-2">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <div class="mb-2">
                        <div class="gro__card__label">Goal on joining</div>
                        <div class="gro__card__label__info">{{ patientInfo.firstGoal }}</div>
                      </div>
                      <div class="gro__card__label" v-if="patientMetrics.medication.currentMedications.length !== 0">Medications</div>
                      <div v-for="(item, index) in patientMetrics.medication.currentMedications">
                        <div class="gro__card__label__info">{{item.name}}</div>
                      </div>
                      <div v-if="patientInfo.surgeryName">
                        <div>
                          <div class="gro__card__label">Surgery</div>
                          <div class="gro__card__label__info">{{ patientInfo.surgeryName }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-2">
                        <div class="gro__card__label">Date joined</div>
                        <div class="gro__card__label__info">{{ formatDate(patientInfo.dateOfJoin, true) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </gro-card>
          <div class="patient-metrics">
            <h3>
              Changes since joining
              <i v-tooltip="`Changes in participant's health since joining`" class="material-icons pointer">info</i>
            </h3>
            <div class="patient-metrics-cards">
                <metric-card
                    :metric-number="formatCardData(patientMetrics.weight.change, patientMetrics.weight.unit)"
                    :metric-title="`Weight change`"
                    :tool-tip="`Change in weight since joining`"
                    :patient-change-arrow="patientMetrics.weight.change !== 0 ? patientMetrics.weight.change : ''"
                />
                <metric-card
                    :metric-number="formatCardData(patientMetrics.hbA1c.change, patientMetrics.hbA1c.unit)"
                    :metric-title="`A1c change`"
                    :tool-tip="`Change in HbA1c since joining`"
                    :patient-change-arrow="patientMetrics.hbA1c.change !== 0 ? patientMetrics.hbA1c.change : ''"
                />
                <metric-card
                    :metric-number="formatCardData(patientMetrics.medication.changeN)"
                    :metric-title="`Medication change`"
                    :tool-tip="`Changes in number of medications since joining`"
                    :patient-change-arrow="patientMetrics.medication.changeN !== 0 ? patientMetrics.medication.changeN : ''"
                />
            </div>
          </div>
          <div class="patient-metrics">
            <h3>Current metrics
              <i v-tooltip="`Participant's current health metrics`" class="material-icons pointer">info</i>
            </h3>
            <div class="patient-metrics-cards">
              <metric-card
                  :metric-number="formatCardData(patientMetrics.weight.current, patientMetrics.weight.unit )"
                  :metric-title="`Weight`"
                  :tool-tip="`Participant's most recent weight reading`"
              />
              <metric-card
                  :metric-number="formatCardData(patientMetrics.hbA1c.current, patientMetrics.hbA1c.unit)"
                  :metric-title="`A1c`"
                  :tool-tip="`Participant's most recent HbA1c reading`"
              />
              <metric-card
                  :metric-number="formatCardData(patientMetrics.medication.currentN)"
                  :metric-title="`Medications`"
                  :tool-tip="`Participant's current amount of medications`"
              />
            </div>
          </div>
          <div class="patient-metrics">
            <h3>Baseline metrics
              <i v-tooltip="`Participant's metrics on joining`" class="material-icons pointer">info</i>
            </h3>
            <div class="patient-metrics-cards">
              <metric-card
                  :metric-number="formatCardData(patientMetrics.weight.baseline, patientMetrics.weight.unit)"
                  :metric-title="`Weight`"
                  :tool-tip="`Participant's weight on joining`"
              />
              <metric-card
                  :metric-number="formatCardData(patientMetrics.hbA1c.baseline, patientMetrics.hbA1c.unit)"
                  :metric-title="`A1c`"
                  :tool-tip="`Participant's HbA1c on joining`"
              />
              <metric-card
                  :metric-number="formatCardData(patientMetrics.medication.baselineN)"
                  :metric-title="`Baseline medications`"
                  :tool-tip="`Number of medications the participant was taking on joining`"
              />
            </div>
          </div>
<!--          <div class="communicate">-->
<!--            <gro-card>-->
<!--              <form-->
<!--                  slot="body"-->
<!--                  id="communication-form"-->
<!--              >-->
<!--                <h3>Communicate</h3>-->
<!--                <div class="communication-form-item">-->
<!--                  <div class="flex-row">-->
<!--                    <div class="d-flex">-->
<!--                      <div class="d-inline-flex mr-4">Send via:</div>-->
<!--                      <div class="d-inline-flex mr-2">-->
<!--                        <gro-checkbox label="Email" value="Email" v-model="communicateForm.sendVia.email"/>-->
<!--                      </div>-->
<!--                      <div class="d-inline-flex mr-2">-->
<!--                        <gro-checkbox label="Push notifications" value="Push" v-model="communicateForm.sendVia.pushNotifications"/>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="communication-form-item mb-4">-->
<!--                  <div class="d-flex">-->
<!--                    <div class="d-inline-flex mr-4">Priority:</div>-->
<!--                    <div class="d-inline-flex mr-3">-->
<!--                      <gro-radio label="Normal" value="normal" v-model="communicateForm.priority"></gro-radio>-->
<!--                    </div>-->
<!--                    <div class="d-inline-flex mr-3">-->
<!--                      <gro-radio label="High" value="High" v-model="communicateForm.priority"></gro-radio>-->
<!--                    </div>-->
<!--                    <div class="d-inline-flex mr-3">-->
<!--                      <gro-radio label="Medium" value="Medium" v-model="communicateForm.priority"></gro-radio>-->
<!--                    </div>-->
<!--                    <div class="d-inline-flex mr-3">-->
<!--                      <gro-radio label="Low" value="Low" v-model="communicateForm.priority"></gro-radio>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="communication-form-item">-->
<!--                  <gro-textarea-->
<!--                      label="Subject"-->
<!--                      placeholder="Subject"-->
<!--                      id="subject"-->
<!--                      name="subject"-->
<!--                      v-model="communicateForm.subject"-->
<!--                      :maxCharacters="60">-->
<!--                  </gro-textarea>-->
<!--                </div>-->
<!--                <div class="communication-form-item">-->
<!--                  <gro-select v-model="communicateForm.template" label="Insert template" placeholder="">-->
<!--                    <gro-select-option :value="1" label="Select 1"/>-->
<!--                    <gro-select-option :value="2" label="Select 2"/>-->
<!--                    <gro-select-option :value="3" label="Select 3"/>-->
<!--                  </gro-select>-->
<!--                </div>-->
<!--                <div class="communication-form-item">-->
<!--                  <gro-textarea-->
<!--                      label="Message to patient"-->
<!--                      placeholder="Type a message to your patient..."-->
<!--                      id="message"-->
<!--                      name="message"-->
<!--                      v-model="communicateForm.message"-->
<!--                      :maxCharacters="160">-->
<!--                  </gro-textarea>-->
<!--                </div>-->
<!--                <div class="communication-form-item">-->
<!--                  <gro-textarea-->
<!--                      label="Internal note (optional)"-->
<!--                      placeholder="Internal note"-->
<!--                      id="internalNote"-->
<!--                      name="internalNote"-->
<!--                      v-model="communicateForm.internalNote"-->
<!--                      :maxCharacters="40">-->
<!--                  </gro-textarea>-->
<!--                </div>-->
<!--                <div class="communication-form-item">-->
<!--                  <p>To automatically insert certain data, please use the following:-->
<!--                    <strong>{{ patientInfo.firstName + ' ' + patientInfo.lastName}}</strong>, <strong>{SURGERY_ADDRESS}</strong></p>-->
<!--                </div>-->
<!--                <div class="communication-form-item">-->
<!--                  <div class="flex-row">-->
<!--                    <div class="d-flex justify-content-end align-items-center">-->
<!--                      <div class="d-inline-flex mr-4">-->
<!--                        <gro-checkbox label="Save as template" :value="1" v-model="communicateForm.saveAsTemplate"/>-->
<!--                      </div>-->
<!--                      <div class="d-inline-flex mr-2">-->
<!--                        <p class="preview">Preview message</p>-->
<!--                      </div>-->
<!--                      <div class="d-inline-flex mr-2">-->
<!--                        <button @click.prevent="submitForm()">Send</button>-->
<!--                        <input type="submit" style="display: none;" @click.prevent="submitForm()" />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </form>-->
<!--            </gro-card>-->
<!--          </div>-->
        </div>
        <div v-if="patientInfo" class="patient-details-charts col-sm-12 col-md-12 col-lg-4">
          <div class="graph-widget blood-glucose-graph mb-3">
            <div class="graph-widget-title">
              Blood glucose
              <i v-tooltip="`Participant's blood glucose levels over time`" class="material-icons pointer"
                 style="color: white; float:right">info</i>
            </div>
            <div class="graph-widget-date-range">
              {{formatDate(patientInfo.dateOfJoin)}} - {{ formatDateNow() }}
            </div>
            <div class="line-chart-label">Avg. weekly</div>
            <gro-line-chart
                style="height: 100px;"
                :chart-data="{
                labels: ['Start', 'Now'],
                datasets: [
                  {
                    label: '',
                    backgroundColor: 'white',
                    borderColor: 'white',
                    data: [patientMetrics.bloodGlucose.baseline, patientMetrics.bloodGlucose.current]
                  }
                ]
            }">
            </gro-line-chart>
<!--            <div style="height: 100px" class="d-flex align-items-center">-->
<!--              <div class="d-flex m-auto align-items-center">-->
<!--                <div class="d-inline-flex pr-2">-->
<!--                  <img src="/loading-spinner-light.svg">-->
<!--                </div>-->
<!--                <div class="d-inline-flex">-->
<!--                  sharedLoading-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="row mt-3">
              <div class="col-6">
                <div class="line-graph-bottom-label">
                  Start
                </div>
              </div>
              <div class="col-6 text-right">
                <div class="line-graph-bottom-label">
                  Now
                </div>
              </div>
            </div>
          </div>
          <div class="graph-widget a1c-graph mb-3">
            <div class="graph-widget-title">
              HbA1c
              <i v-tooltip="`Participant's HbA1c over time`" class="material-icons pointer"
                 style="color: white; float:right">info</i>
            </div>
            <div class="graph-widget-date-range">
              {{formatDate(patientInfo.dateOfJoin)}} - {{ formatDateNow() }}
            </div>
            <div class="line-chart-label">Avg. weekly</div>
            <gro-line-chart
                style="height: 100px;"
                :chart-data="{
                labels: ['Start', 'Now'],
                datasets: [
                  {
                    label: '',
                    backgroundColor: 'white',
                    borderColor: 'white',
                    data: [patientMetrics.hbA1c.baseline, patientMetrics.hbA1c.current]
                  }
                ]
            }">
            </gro-line-chart>
            <!--            <div style="height: 100px" class="d-flex align-items-center">-->
            <!--              <div class="d-flex m-auto align-items-center">-->
            <!--                <div class="d-inline-flex pr-2">-->
            <!--                  <img src="/loading-spinner-light.svg">-->
            <!--                </div>-->
            <!--                <div class="d-inline-flex">-->
            <!--                  sharedLoading-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="row mt-3">
              <div class="col-6">
                <div class="line-graph-bottom-label">
                  Start
                </div>
              </div>
              <div class="col-6 text-right">
                <div class="line-graph-bottom-label">
                  Now
                </div>
              </div>
            </div>
          </div>
          <div class="graph-widget weight-graph">
            <div class="graph-widget-title">
              Weight
              <i v-tooltip="`Participant's weight readings over time`" class="material-icons pointer"
                 style="color: white; float:right">info</i>
            </div>
            <div class="graph-widget-date-range">
              {{formatDate(patientInfo.dateOfJoin)}} - {{ formatDateNow() }}
            </div>
            <div class="line-chart-label">Avg. weekly</div>
            <gro-line-chart
                style="height: 100px;"
                :chart-data="{
                labels: ['Start', 'Now'],
                datasets: [
                  {
                    label: '',
                    backgroundColor: 'white',
                    borderColor: 'white',
                    data: [patientMetrics.weight.baseline, patientMetrics.weight.current]
                  }
                ]
            }">
            </gro-line-chart>
            <!--            <div style="height: 100px" class="d-flex align-items-center">-->
            <!--              <div class="d-flex m-auto align-items-center">-->
            <!--                <div class="d-inline-flex pr-2">-->
            <!--                  <img src="/loading-spinner-light.svg">-->
            <!--                </div>-->
            <!--                <div class="d-inline-flex">-->
            <!--                  sharedLoading-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="row mt-3">
              <div class="col-6">
                <div class="line-graph-bottom-label">
                  Start
                </div>
              </div>
              <div class="col-6 text-right">
                <div class="line-graph-bottom-label">
                  Now
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MetricCard from "@/components/MetricCard/MetricCard";
  import AnalyticsApi from "@/api/Analytics";
  import moment from 'moment'
  import axios from "axios";

  export default {
    name: "PatientDetails",
    components: {
      MetricCard
    },
    data() {
      return {
        communicateForm: {
          template: null,
          sendVia: {
            email: false,
            pushNotifications: false,
          },
          priority: null,
          subject: null,
          message: null,
          internalNote: null,
          saveAsTemplate: 0,
        },
        patientInfo: null,
        patientMetrics: null,
        patientIsLoading: false,
      }
    },
    computed: {
      stringMedications() {
        return this.patientMetrics.medication.currentMedications.map(e => e.name).join(", ");
      }
    },
    methods: {
      formatCardData(type, unit) {
        if (type === 0) {
          return 'N/A'
        } else {
          if (unit) {
            return type.toFixed(1) + unit;
          } else  {
            return type.toFixed();
          }
        }
      },
      formatDateNow() {
        return moment().format('DD/MM/YYYY');
      },
      formatDate(date, joined) {
        if (joined) {
          return `${moment(date).format('DD/MM/YYYY')} (${moment(date).fromNow()})`
        } else {
          return moment(date).format('DD/MM/YYYY')
        }
      },
      getPatientData() {
        let self = this;
        self.patientIsLoading = true;
        axios.all([
                AnalyticsApi.getPatientHealth(self.$store.getters.storedProvider.id, self.$route.params.id),
                AnalyticsApi.getPatientDetails(self.$store.getters.storedProvider.id, self.$route.params.id)
        ])
            .then(axios.spread((firstResponse, secondResponse) => {
              self.patientMetrics = firstResponse.data;
              self.patientInfo = secondResponse.data;
              self.patientIsLoading = false;
            }))
      },
      calcPercentage(current, starting) {
        if (current) {
          if (current === 0) {
            return ''
          } else {
            return (((current - starting) / current) * 100).toFixed(1);
          }
        } else {
          return '';
        }
        // = % out of 100 (positive is gain, negative is reduced)
      },
    },
    mounted() {
      this.getPatientData()
    }
  }
</script>

<style scoped lang="scss">
  @import "~@gro-dev/gro-component-kit-for-web/src/theme/mixins/gradients";
  #patient-details {
    .patient-details-inner {
      max-width: 950px;
      margin: 0 auto;
      .patient-details-info {
        .patient-details-title {
          margin: 24px 0;
          h1 {
            font-family: $poppins;
            font-weight: 600;
            color: $theme-color;
            font-size: 1.8rem;
            margin-bottom: 8px;
          }
          h2 {
            font-family: $poppins;
            color: $black;
            font-weight: normal;
            font-size: 1.5rem;
            strong {
              font-family: $poppins;
              font-weight: 600;
            }
          }
        }
        .gro__card {
          margin-bottom: 32px;
          @include rounded(14px);
          @include box-shadow(none);
          .gro__card__avatar {
            @include rounded(50%);
            width: 100%;
          }
          .gro__card__info__title {
            font-family: $poppins;
            font-weight: 700;
            color: $theme-color;
            line-height: 27px;
            margin-bottom: 0;
            i {
              color: #008a00;
              font-size: 20px;
              vertical-align: middle;
              margin: 0 0 0 6px;
            }
          }
          .gro__card__label__user_id {
            font-size: 12px;
            color: #5b617c;
            letter-spacing: -0.3px;
            margin-bottom: 8px;
          }
          .gro__card__label__diabetes__type {
            font-size: 12px;
            font-family: $poppins;
            letter-spacing: -0.3px;
          }
          .gro__card__label {
            font-size: 12px;
            font-family: $poppins;
            margin-bottom: 2px;
          }
          .gro__card__label__info {
            font-size: 1rem;
            font-family: $poppins;
            .flag-icon {
              @include rounded(2px);
              margin: 0 8px 0 0;
            }
            &.isBlue {
              color: $theme-color;
              font-weight: 600;
            }
          }
        }
        .patient-metrics {
          h3 {
            color: $theme-color;
            font-family: $poppins;
            font-size: 1.3rem;
            margin: 0 0 0 0;
            font-weight: 600;
            i {
              color: #c8c7cc;
              font-size: 10px;
              vertical-align: super;
            }
          }
          .patient-metrics-cards {
            margin: 15px 0;
            @include flexbox();
            @include flexbox-direction(row);
          }
        }
        .communicate {
          #communication-form {
            h3 {
              color: $theme-color;
              font-family: $poppins;
              font-weight: 600;
              font-size: 1.8rem;
            }
            .communication-form-item {
              margin: 15px 0;
              p {
                font-family: $poppins;
                font-size: 1rem;
                margin: 0 10px;
                strong {
                  font-weight: bold;
                }
                &.preview {
                  color: $theme-color;
                  font-weight: 500;
                }
              }
              button {
                background-color: #008a00;
                font-family: $poppins;
                padding: 20px 45px;
                border: none;
                font-weight: 600;
                color: $white;
                font-size: 1.3rem;
                cursor: pointer;
                @include transition(all, 0.2s, ease-in-out);
                @include rounded(4px);
                &:hover {
                 background-color: rgb(0 138 0 / 71%);
                }
              }
            }
          }
        }
      }
      .patient-details-charts {
        margin-top: 152px;
        $graph-widget-small-height: 84px;

        .graph-widget {
          position: relative;
          padding: 18px;
          border-radius: 13px;
          color: white;

          .graph-widget-title {
            font-size: 22px;
            font-family: $poppins;
            font-weight: 500;

            .material-icons {
              position: relative;
              top: -4px;
              right: -4px;
            }
          }

          .graph-widget-date-range {
            font-family: $poppins;
            font-size: 14px;
            margin-bottom: 12px;
          }

          .line-chart-label {
            position: absolute;
            left: -20px;
            transform: rotate(-90deg);
            top: 50%;
            bottom: 50%;
            font-family: $poppins;
            font-size: 9px;
          }

          img {
            -webkit-animation: rotating 5s linear infinite;
            -moz-animation: rotating 5s linear infinite;
            -ms-animation: rotating 5s linear infinite;
            -o-animation: rotating 5s linear infinite;
            animation: rotating 5s linear infinite;
          }
        }

        .graph-widget-small {
          height: $graph-widget-small-height;

          img {
            -webkit-animation: rotating 5s linear infinite;
            -moz-animation: rotating 5s linear infinite;
            -ms-animation: rotating 5s linear infinite;
            -o-animation: rotating 5s linear infinite;
            animation: rotating 5s linear infinite;
          }
        }

        .line-graph-bottom-label {
          font-family: $poppins;
          font-size: 14px;
        }


        #chart-tooltip {
          position: absolute;
          background: white;
          padding: 10px;
          border-radius: 16px;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
        }

        .blood-glucose-graph {
          @include generateLinearGradient(#e52d27, #c3003c, 236deg);
        }

        .a1c-graph {
          @include generateLinearGradient(#e52d27, #c3003c, 236deg);
        }

        .weight-graph {
          @include generateLinearGradient(#383cbf, #0fbaed, 236deg)
        }

      }
    }
  }
</style>