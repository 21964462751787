import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/Views/Login/Login.vue'
import store from '@/store/index.js'
import Dashboard from "@/Views/Dashboard/Dashboard";
import Patients from "@/Views/Patients/Patients";
import PatientDetails from "@/Views/PatientDetails/PatientDetails";

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { requireAuth: false, hideNav: true, icon: 'mdi-account' },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requireAuth: true, hideNav: false, icon: 'lightbulb' }
    },
    {
        path: '/patients',
        name: 'Patients',
        component: Patients,
        meta: { requireAuth: true, hideNav: false, icon: 'face' }
    },
    {
        path: '/patients/:id',
        name: 'PatientDetails',
        component: PatientDetails,
        meta: { requireAuth: true, hideNav: true, icon: 'lightbulb' }
    },
    {
        path: '/patients/search/:searchTerm',
        name: 'PatientsSearch',
        component: Patients,
        meta: { requireAuth: true, hideNav: true, icon: 'lightbulb' }
    },
    {
        path: "/",
        redirect: "/dashboard",
        meta: { requireAuth: true, hideNav: true, icon: 'lightbulb' }
    },
];


const router = new VueRouter({
    routes
});
export default router
