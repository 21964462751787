import axios from 'axios'
import config from '@/config'
import store from '@/store'

let AnalyticsApi = {
    getProviderList() {
        return axios.get(config.apiURL + 'providers')
    },
    getProviderSummary(id) {
      return axios.get(config.apiURL + 'provider/summary/' + id)
    },
    getProviderDetails(id) {
        return axios.get(config.apiURL + 'provider/detail/' + id)
    },
    getProviderCodes(id, page = 1, perPage = 5) {
        return axios.get(config.apiURL + 'provider/codes/' + id + '/' + perPage + '/' + page)
    },
    getProviderPatients(id, page = 1, perPage = 10) {
        return axios.post(config.apiURL + 'provider/patients/' + id + '/' + perPage + '/' + page, {
            ...(store.getters.searchTerm ? { email: store.getters.searchTerm } : {})
        })
    },
    getPatientDetails(groupID, userID) {
        return axios.get(config.apiURL + 'patient/detail/' + groupID + '/' + userID)
    },
    getPatientHealth(groupID, userID) {
        return axios.get(config.apiURL + 'patient/health/' + groupID + '/' + userID)
    },
    getExportedData(id) {
        return axios.get(config.apiURL + 'patient/detail/' + id)
    },
};

export default AnalyticsApi;
