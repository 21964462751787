import axios from 'axios'
import config from '../../config'

const apiUrl = config.lcpAPI;

let TokenApi = {
  async refreshAccessToken(refreshToken) {
    return axios.post(apiUrl + 'token/refresh', {
      refreshToken: refreshToken
    });
  }
};

export default TokenApi;
