<template>
  <div id="patients">
    <div class="p-5 text-center" v-if="initialLoad">
      <gro-spinner color="primary" size="lg"></gro-spinner>
    </div>
    <transition name="fade">
      <div class="patients-inner" v-if="!initialLoad">
        <div class="search-term mb-3" v-if="$route.params.searchTerm">
          <p>Showing results for "{{$route.params.searchTerm}}"</p>
        </div>
        <div class="patients-title">
          <h1>My patients</h1>
          <div class="d-flex">
            <div class="export-button mr-3" v-if="hasExportAccess" :class="{'isDisabled': exportLoad}" @click="exportData()">
              <div class="d-flex" v-if="!exportLoad">
                <i class="material-icons">file_download</i>
                Export data
              </div>
              <div class="d-flex" v-else>
                <svg class="export-spinner mr-3" width="23px" height="23px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
                Loading...
              </div>
            </div>
            <search-bar></search-bar>
          </div>
        </div>
        <div class="p-5 text-center" v-if="patientsIsLoading">
          <gro-spinner color="primary" size="md"></gro-spinner>
        </div>
        <div v-if="!patientsIsLoading">
          <div class="patients-content">
            <div class="patient-item" v-for="(patient, index) in patientsData.patients" :key="`item-${index}`">
              <router-link :to="{name: 'PatientDetails', params: { id: patient.userId }}">
                <gro-card shadow :bordered="false" :borderIndicatorColor="getCardColour(patient.progress)">
                  <div slot="body">
                    <div class="mb-2">
                      <div class="text-primary gro__card__info__title">{{ patient.firstName + ' ' + patient.lastName }}</div>
                      <div class="gro__card__label__user_id">User {{patient.userId}}</div>
                      <div class="gro__card__label__diabetes__type">{{ patient.diabetesType }}</div>
                    </div>
                    <div class="divider"></div>
                    <div class="mb-2">
                      <div class="gro__card__label">Baseline A1c</div>
                      <div class="gro__card__label__info">{{ patient.hbA1c.baseline ? patient.hbA1c.baseline.toFixed(1) : patient.hbA1c.baseline }} mmol/mol</div>
                    </div>
                    <div class="mb-2">
                      <div class="gro__card__label">Baseline weight</div>
                      <div class="gro__card__label__info">{{ patient.weight.baseline }}kg</div>
                    </div>
                    <div class="divider"></div>
                    <div class="mb-2">
                      <div class="gro__card__label">Current A1c</div>
                      <div class="gro__card__label__info">
                        {{ patient.hbA1c.current ? patient.hbA1c.current.toFixed(1) : patient.hbA1c.current }}  mmol/mol
                        <div v-if="calcPercentage(patient.hbA1c.current, patient.hbA1c.baseline) != 0 || calcPercentage(patient.hbA1c.current, patient.hbA1c.baseline) != 0.0" :class="calcPercentageIcon(patient.hbA1c.current, patient.hbA1c.baseline)">({{calcPercentage(patient.hbA1c.current, patient.hbA1c.baseline)}} mmol/mol<font-awesome-icon  :icon="calcPercentageIcon(patient.hbA1c.current, patient.hbA1c.baseline)"></font-awesome-icon>)</div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <div class="gro__card__label">Current weight</div>
                      <div class="gro__card__label__info">{{ patient.weight.current }}kg
                        <div v-if="calcPercentage(patient.weight.current, patient.weight.baseline) != 0 || calcPercentage(patient.weight.current, patient.weight.baseline) != 0.0" :class="calcPercentageIcon(patient.weight.current, patient.weight.baseline)">({{calcPercentage(patient.weight.current, patient.weight.baseline)}}kg<font-awesome-icon  :icon="calcPercentageIcon(patient.weight.current, patient.weight.baseline)"></font-awesome-icon>)</div>
                      </div>
                    </div>
                    <div class="divider"></div>
                    <div>
                      <div class="gro__card__label">Current progress</div>
                      <div class="gro__card__label__info">{{ formatProgress(patient.progress) }}</div>
                    </div>
                    <div v-if="patient.surgeryName">
                      <div class="divider"></div>
                      <div>
                        <div class="gro__card__label">Surgery</div>
                        <div class="gro__card__label__info">{{ patient.surgeryName }}</div>
                      </div>
                    </div>
                  </div>
                </gro-card>
              </router-link>
            </div>
          </div>
          <div class="noPatients" v-if="patientsData.patients.length === 0">
            <p>No data available for: {{$store.state.searchTerm}}</p>
          </div>
        </div>
        <div class="pagination-wrap" v-if="patientsData.totalPages !== 0">
          <p>Page {{pageNumber}} of {{ patientsData.totalPages }}</p>
          <gro-paginator
              v-if="patientsData.patients.length >= 2"
              :page-count="patientsData.totalPages"
              :force-page="3"
              v-model="pageNumber"
              :margin-pages="2"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :page-class="'page-item'">
          </gro-paginator>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import AnalyticsApi from "@/api/Analytics";
  import SearchBar from "../../components/SearchBar/SearchBar";

  export default {
    name: "Patients",
    components: {SearchBar},
    data() {
      return {
        pageNumber: 1,
        patientsData: null,
        patientsIsLoading: false,
        initialLoad: true,
        exportLoad: false,
        hasExportAccess: false,
      }
    },
    watch: {
      pageNumber: function() {
        if (this.$route.name === 'PatientsSearch') {
          this.searchByEmail();
        } else {
          this.getPatients();
        }
      },
      $route: function() {
        if (this.$route.name === 'PatientsSearch') {
          this.$store.commit('search_patients', { searchTerm: this.$route.params.searchTerm});
          this.searchByEmail();
        } else {
          this.initialLoad = true;
          this.$store.commit('search_patients', { searchTerm: ''});
          this.getPatients();
        }
      }
    },
    methods: {
      exportData() {
        let self = this;
        self.exportLoad = true;
        AnalyticsApi.getExportedData(self.$store.getters.storedProvider.id)
          .then((res) => {
            const { Parser } = require('json2csv');
            const fields = ['userId', 'firstName', 'lastName', 'diabetesType', 'progress', 'location', 'ethnicity', 'dateOfBirth', 'email', 'dateOfJoin', 'firstGoal', 'profilePictureURL', 'weight.baseline', 'weight.current','weight.change', 'weight.unit', 'hbA1c.baseline', 'hbA1c.current','hbA1c.change', 'hbA1c.unit',  'bloodGlucose.baseline', 'bloodGlucose.current','bloodGlucose.change', 'bloodGlucose.unit'];
            const opts = { fields };

            try {
              const parser = new Parser(opts);
              const csv = parser.parse(res.data);
              const anchor = document.createElement('a');
              anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
              anchor.target = '_blank';
              anchor.download = `${self.$store.getters.storedProvider.name}-patients.csv`;
              anchor.click();
            } catch (err) {
              console.error(err);
            }
            self.exportLoad = false;
          })
      },
      formatProgress(count) {
        if (count === 0 || count == null) {
          return 'N/A'
        } else {
          return `${count.toFixed(1)}%`;
        }
      },
      calcPercentageIcon(current, starting) {
        let self = this;
        let checkValue = self.calcPercentage(current, starting);
        if (checkValue.toString().indexOf('-') !== -1) {
          return 'caret-down'
        } else {
          return 'caret-up'
        }
      },
      calcPercentage(current, starting) {
        if (current) {
          return (current - starting).toFixed(1);
        } else {
          return '';
        }
        // = % out of 100 (positive is gain, negative is reduced)
      },
      searchByEmail() {
        let self = this;
        self.patientsIsLoading = true;
        AnalyticsApi.getProviderPatients(self.$store.getters.storedProvider.id, self.pageNumber, 10)
          .then((res) => {
            self.patientsData = res.data;
            self.patientsIsLoading = false;
            self.initialLoad = false;
          })
          .catch((err) => {
            console.error(err)
          })
      },
      getCardColour: function(progress) {
        if (progress === 100) {
          return '#008a00'
        } else if(progress >= 50) {
          return '#d56f01'
        } else if(progress < 50) {
          return '#dc143c'
        }
      },
      getPatients() {
        let self = this;
        self.patientsIsLoading = true;
        AnalyticsApi.getProviderPatients(self.$store.getters.storedProvider.id, self.pageNumber)
            .then(function(response) {
              self.patientsData = response.data;
              self.patientsIsLoading = false;
              self.initialLoad = false;
            })
      },
      goToPatient(user_id) {
        let self = this;
        self.$router.push({name: 'PatientDetails', params: { id: user_id }})
      }
    },
    mounted() {
      if (this.$store.getters.user === 'krish@ddm.health' || this.$store.getters.user === 'analytics@lcp.com') {
        this.hasExportAccess = true;
      } else {
        this.hasExportAccess = false;
      }
      if (this.$route.name === 'PatientsSearch') {
        this.$store.commit('search_patients', { searchTerm: this.$route.params.searchTerm});
        this.searchByEmail();
      } else {
        this.initialLoad = true;
        this.$store.commit('search_patients', { searchTerm: ''});
        this.getPatients();
      }
    }
  }
</script>

<style scoped lang="scss">
  #patients {
    .patients-inner {
      max-width: 850px;
      margin: 0 auto;
      .search-term {
        p {
          font-family: $poppins;
          color: $theme-color;
          font-weight: 500;
          text-align: right;
        }
      }
      .patients-title {
        @include flexbox();
        @include flexbox-align-items(center);
        @include flexbox-justify-content(space-between);
        margin: 0 0 20px 0;
        h1 {
          font-family: $poppins;
          font-weight: 600;
          color: $theme-color;
          font-size: 2rem;
        }
        .export-button {
          padding: 12px 32px 12px 24px;
          border-radius: 8px;
          background-color: #e1edf9;
          color: $theme-color;
          display: flex;
          cursor: pointer;
          max-height: 47.91px;
          max-width: 175px;
          transition: all .2s ease-in-out;
          &:hover {
            transform: scale(1.02);
          }
          &.isDisabled {
            opacity: .8;
            cursor: not-allowed;
            pointer-events: none;
          }
          div {transition: all .2s ease-in-out;}
          i {
            margin-right: 5px;
            color: $theme-color;
          }
          $offset: 187;
          $duration: 1.4s;

          .export-spinner {
            animation: rotator $duration linear infinite;
          }

          @keyframes rotator {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(270deg); }
          }

          .path {
            stroke: $theme-color;
            stroke-dasharray: $offset;
            stroke-dashoffset: 0;
            transform-origin: center;
            animation: dash $duration ease-in-out infinite;
          }

          @keyframes dash {
            0% { stroke-dashoffset: $offset; }
            50% {
              stroke-dashoffset: $offset/4;
              transform:rotate(135deg);
            }
            100% {
              stroke-dashoffset: $offset;
              transform:rotate(450deg);
            }
          }
        }
      }
      .patients-content {
        @include flexbox();
        @include flexbox-wrap(wrap);
        .patient-item {
          @include flexbox-flex(1 1 25%);
          margin: 0 8px 8px 0;
          cursor: pointer;
          @include transition();
          &:hover {
            transform: scale(1.02);
          }
          a {
            text-decoration: none;
            color: inherit;
          }
          &:nth-of-type(3n), &:last-of-type {
            margin-right: 0;
          }
        }
        .gro__card__info__title {
          font-family: $poppins;
          font-weight: 700;
          color: $theme-color;
          line-height: 27px;
          margin-bottom: 0;
          word-break: break-word;
        }
        .gro__card__label__user_id {
          font-size: 12px;
          color: #5b617c;
          letter-spacing: -0.3px;
          margin-bottom: 8px;
        }
        .gro__card__label__diabetes__type {
          font-size: 12px;
          font-family: $poppins;
          letter-spacing: -0.3px;
        }
        .gro__card__label {
          font-size: 12px;
          font-family: $poppins;
          margin-bottom: 2px;
        }
        .gro__card__label__info {
          font-size: 1rem;
          font-family: $poppins;
          .caret-down {
            color: #008000;
            display: inline;
          }
          .caret-up {
            color: #dc143c;
            display: inline;
          }
        }
      }
      .noPatients {
        p {
          text-align: center;
          font-size: 1.2rem;
          font-weight: 500;
          margin: 15px 0;
        }
      }
      .pagination-wrap {
        padding: 20px 0 30px;
        @include flexbox();
        @include flexbox-align-items(center);
        @include flexbox-justify-content(center);
        p {
          font-family: $poppins;
          font-size: 14px;
          margin: 0 70px 0 0;
        }
      }
    }
  }
</style>