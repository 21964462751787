<template>
  <div id="login">
    <form class="login-card" @submit.prevent="submitLogin()">
      <gro-card>
        <div slot="header">
          <img src="@/assets/LCP-Logo-Blue.svg" width="220" alt="Low Carb Program">
        </div>
        <div slot="body">
          <div class="login-email">
            <gro-input label="Email" id="login_email" name="email" placeholder="Enter email"  v-model="login.email" required="required">
              <div slot="leftIcon" class="d-flex">
                <i class="material-icons">email</i>
              </div>
            </gro-input>
          </div>
          <div class="login-password">
            <gro-input label="Password" id="login_password" name="password" placeholder="Enter password" :type="showPass ? 'text' : 'password'" v-model="login.password" required="required">
              <div slot="leftIcon" class="d-flex" @click="showPass = !showPass">
                <i class="material-icons" v-if="showPass">visibility</i>
                <i class="material-icons" v-else>visibility_off</i>
              </div>
            </gro-input>
          </div>
        </div>
        <div slot="footer">
          <div class="login-submit">
            <gro-button class="w-100"
                        size="xl"
                        type="submit"
                        centered
                        :loading="buttonLoader"
                        loadingTitle="Loading...">
              Login
              <i v-if="!buttonLoader" slot="rightIcon" class="icon icon-chevron-right"></i>
            </gro-button>
            <gro-notice class="mt-3" variant="danger" v-if="error || accessDenied || incorrectDetails">
              <div slot="icon">
                <i class="material-icons">report_problem</i>
              </div>
              <span v-if="error">Login failed</span>
              <span v-if="accessDenied">Access denied</span>
              <span v-if="incorrectDetails">Your credentials are incorrect</span>
            </gro-notice>
          </div>
        </div>
      </gro-card>
    </form>
  </div>
</template>

<script>
  import LoginApi from "@/api/Login";
  import AuthenticationManager from "@/helpers/AuthenticationManager";

  export default {
    name: "Login.vue",
    data: () => ({
      accessDenied: false,
      error: false,
      incorrectDetails: false,
      showPass: false,
      isLoading: false,
      valid: true,
      login: {
        email: '',
        password: '',
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      lazy: false,
      buttonLoader: false,
    }),
    methods: {
      submitLogin() {
        let self = this;
        self.incorrectDetails = false;
        self.accessDenied = false;
        self.error = false;

        self.buttonLoader = true;
        LoginApi.Authenticate(self.login.email, self.login.password, true, null)
        .then(function(response) {
          const token = AuthenticationManager.setAccessTokenExpiryTime(response.data);
          AuthenticationManager.setToken(token);
          if (AuthenticationManager.parseJwt().scope.indexOf('administrator_tasks') === -1) {
            if (AuthenticationManager.parseJwt().scope.indexOf('hcp_tasks') === -1) {
              throw new Error('Access denied');
            }
          }
          self.$store.commit('logged_in', true);
          self.$store.commit('set_user', self.login.email)
          self.$router.push({name: 'Dashboard'});
        })
        .catch(function(error) {
          if (error.message === 'Access denied') {
            AuthenticationManager.clearSession();
            sessionStorage.clear();
            self.buttonLoader = false;
            self.accessDenied = true;
          } else {
            switch (error.response.data.type) {
              case 'INVALID_CREDENTIALS':
                self.buttonLoader = false;
                self.incorrectDetails = true;
                break;
              default:
                self.buttonLoader = false;
                self.error = true;
                break;
            }
          }
        })
      }
    },
    mounted() {
      if (AuthenticationManager.hasToken()) {
        this.$router.replace({name: 'Dashboard'});
      }
    }
  }
</script>

<style scoped lang="scss">
  #login {
    height: 100%;
    @include flexbox();
    @include flexbox-justify-content(center);
    @include flexbox-align-items(center);
    .login-card {
      max-width: 650px;
      padding: 18px;
      margin: 0 auto;
      width: 100%;
      .login-password {
        margin: 15px 0 0 0;
      }
      .login-email {
        margin: 0 0 15px 0;
      }
      .login-submit {
        margin-bottom: 20px;
      }
    }
  }
</style>