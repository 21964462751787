import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        user: '',
        searchTerm: '',
        isLoggedIn: null,
        storedProvider: null,
    },
    mutations: {
        search_patients(state, payload){
          state.searchTerm = payload.searchTerm;
        },
        set_user(state, payload) {
            state.user = payload;
        },
        logged_in(state, payload) {
            state.isLoggedIn = payload;
        },
        set_provider(state, payload) {
            state.storedProvider = payload;
        },
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        user: state => state.user,
        searchTerm: state => state.searchTerm,
        storedProvider: state => state.storedProvider,
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})
