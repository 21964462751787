/**
 * Created by Kieran on 11/09/2017.
 */
import axios from 'axios';
import Cookie from 'js-cookie'
import TokenApi from "../../api/Token";
import _ from 'lodash'
import timeZoneDetector from "jstimezonedetect";

let AuthenticationManager = {
  clearSession() {
    Cookie.remove('lcp_jwt', {path: '/'});
    window.localStorage.removeItem('token');
    if (_.has(axios.defaults.headers.common, 'Authorization')) {
      axios.defaults.headers.common["Timezone"] = timeZoneDetector.determine().name();
      delete axios.defaults.headers.common['Authorization'];
    }
  },
  hasToken() {
    const token = window.localStorage.getItem('token');
    return token !== (null && undefined && "undefined");
  },
  getToken() {
    return JSON.parse(window.localStorage.getItem('token'));
  },
  setToken(tokenObject) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenObject.accessToken;
    axios.defaults.headers.common["Timezone"] = timeZoneDetector.determine().name();
    window.localStorage.setItem('token', JSON.stringify(tokenObject));
  },
  setAccessTokenExpiryTime(tokenObject) {
    //API returns expiry in seconds, js time is in ms for some reason, hence * 1000
    tokenObject.expires = new Date((new Date()).getTime() + ((0.8 * tokenObject.expiresIn) * 1000));
    return tokenObject;
  },
  async refreshAccessToken(tokenObject) {
    if(!_.has(tokenObject, 'refreshToken')) {
      return;
    }

    const response = await TokenApi.refreshAccessToken(tokenObject.refreshToken);
    let tokenData = this.setAccessTokenExpiryTime(response.data);
    this.setToken(tokenData);
  },
  parseJwt() {

    if(!_.has(this.getToken(), 'accessToken'))  {
      return;
    }

    let token = this.getToken().accessToken;
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }
};

export default AuthenticationManager;
