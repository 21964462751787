import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import config from '@/config'
// import * as Cookies from "js-cookie";
import GroUI from '@gro-dev/gro-component-kit-for-web'
import GroUIPlugins from '@gro-dev/gro-component-kit-for-web/dist/plugins/gro-ui-kit-plugins.common'
import '@gro-dev/gro-component-kit-for-web/dist/client/gro-ui-kit.css'
import 'flag-icon-css/css/flag-icon.css'
import VTooltip from 'v-tooltip'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import AuthenticationManager from "@/helpers/AuthenticationManager";
import moment from "moment";
import _ from 'lodash'

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)


library.add(faCaretDown, faCaretUp)
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(VTooltip)
Vue.use(GroUI);
Vue.use(GroUIPlugins);

const tokenObject = JSON.parse(window.localStorage.getItem('token'));
if (_.has(tokenObject, 'accessToken')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenObject.accessToken
}

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {

  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url.indexOf('token/refresh') !== -1) {
    router.push('/login');
    return Promise.reject(error);
  }

  if(originalRequest.url.indexOf(config.apiUrl) === -1) {
    return axios(originalRequest);
  }

  if (error.response.status === 401 && !originalRequest._retry && originalRequest.url.indexOf('login/v2') === -1) { // If it is a login route, dont do a retry to avoid the bug discovered
    originalRequest._retry = true;
    const token = AuthenticationManager.getToken();
    return AuthenticationManager.refreshAccessToken(token)
        .then(() => {
          const newToken = AuthenticationManager.getToken();

          if (_.has(newToken, 'accessToken')) {
            error.config.headers['Authorization'] = 'Bearer ' + newToken.accessToken;
          }

          return axios(originalRequest);
        });
  }
  return Promise.reject(error);
  // const originalRequest = error.config;
  //
  // if (error.response.status === 401 && originalRequest.url.indexOf('token/refresh') !== -1) {
  //   console.log(error)
  //   router.push('/login');
  //   return Promise.reject(error);
  // }
  //
  // if(originalRequest.url.indexOf(config.lcpAPI) === -1) {
  //   return axios(originalRequest);
  // }
  //
  // if (error.response.status === 401 && !originalRequest._retry && originalRequest.url.indexOf('login/') === -1) {
  //   originalRequest._retry = true;
  //   const token = AuthenticationManager.getToken();
  //   return AuthenticationManager.refreshAccessToken(token)
  //       .then(() => {
  //         const newToken = AuthenticationManager.getToken();
  //         if (_.has(newToken, 'accessToken')) {
  //           error.config.headers['Authorization'] = 'Bearer ' + newToken.accessToken;
  //         }
  //
  //         return axios(originalRequest);
  //       })
  // }
  // return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  if(to.meta.requireAuth) {
    if (AuthenticationManager.hasToken()) {
      const doExpiryCheck = (next) => {
        let parsedJwt = AuthenticationManager.parseJwt();
        let {exp} = parsedJwt;
        let expiry = moment.unix(exp);

        console.info('TokenWillRefreshInSeconds', expiry.diff(moment(), 'seconds'));

        if (expiry.diff(moment(), 'seconds') > 0) {
          next();
        } else {
          doExpiryCheck(next())
        }
      }

      setToken().then(() => {
        doExpiryCheck(next)
      }).catch((error) => {
        next({path: '/login'})
      });
    } else {
      next({path: '/login'})
    }
  } else {
    next()
  }
});

const setToken = async () => {
  const tokenObject = AuthenticationManager.getToken();
  let parsedJwt = AuthenticationManager.parseJwt();
  let {exp} = parsedJwt;
  let expiry = moment.unix(exp);
  if (expiry.diff(moment(), 'seconds') <= 0) {
    await AuthenticationManager.refreshAccessToken(tokenObject);
  }
  else {
    await AuthenticationManager.setToken(tokenObject);
  }
};


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
