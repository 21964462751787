import axios from 'axios'
import config from '../../config'

let LoginApi = {
    /**
     * @param email
     * @param password
     * @param remember_me
     * @param offerCode
     * @constructor
     */
    Authenticate(email, password, remember_me, offerCode = null) {
        return axios.post(config.lcpAPI + 'login/', {
            email: email,
            password: btoa(password),
            remember_me: remember_me,
            offerCode: offerCode
        });
    },
};

export default LoginApi
