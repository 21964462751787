<template>
  <div id="dashboard">
    <div class="alert-block text-center" v-if="dataFailed">
      <gro-notice class="mb-3" variant="danger">
        <div slot="icon">
          <i class="material-icons">report_problem</i>
        </div>
        Something went wrong!
        {{errMsg}}
      </gro-notice>
    </div>
    <div class="alert-block text-center" v-if="!$store.getters.storedProvider">
      <gro-notice class="mb-3" variant="info">
        <div slot="icon">
          <i class="material-icons">info</i>
        </div>
        Please choose a provider
      </gro-notice>
    </div>
    <div class="p-5 text-center" v-if="dashIsLoading">
      <gro-spinner color="primary" size="lg"></gro-spinner>
    </div>
    <transition name="fade">
    <div class="fadeWrap" v-if="!dashIsLoading">
      <div class="dashboard-inner" v-if="providerDetails && referralSummary">
        <h1>Physician dashboard</h1>
        <p>Use this screen to see an dashboard of the health of your patients.</p>
        <div class="dashboard-metrics">
          <h2>Health metrics</h2>
          <div class="dashboard-metrics-cards medicine-block">
            <div class="top-metrics">
              <div class="dashboard-metrics-row">
                <metric-card
                    :metric-number="formatCardData(providerDetails.healthMetrics.weight.averageBaseline, providerDetails.healthMetrics.weight.units)"
                    :metric-title="`Avg. baseline weight`"
                    :tool-tip="`Average weight for participants on joining`"
                />
                <metric-card
                    :metric-number="formatCardData(providerDetails.healthMetrics.weight.averageCurrent, providerDetails.healthMetrics.weight.units)"
                    :metric-title="`Avg. current weight`"
                    :tool-tip="`Average weight for participants now`"
                    :metric-percent="calcPercentage(providerDetails.healthMetrics.weight.averageCurrent, providerDetails.healthMetrics.weight.averageBaseline, providerDetails.healthMetrics.weight.units)"
                />
              </div>
              <div class="dashboard-metrics-row">
                <metric-card
                    :metric-number="formatCardData(providerDetails.healthMetrics.hbA1c.averageBaseline, providerDetails.healthMetrics.hbA1c.units)"
                    :metric-title="`Avg. baseline A1c`"
                    :tool-tip="`Average A1c for participants on joining`"
                />
                <metric-card
                    :metric-number="formatCardData(providerDetails.healthMetrics.hbA1c.averageCurrent, providerDetails.healthMetrics.hbA1c.units)"
                    :metric-title="`Avg. current A1c`"
                    :tool-tip="`Average A1c for participants now`"
                    :metric-percent="calcPercentage(providerDetails.healthMetrics.hbA1c.averageCurrent, providerDetails.healthMetrics.hbA1c.averageBaseline,  providerDetails.healthMetrics.hbA1c.units)"
                />
              </div>
              <div class="dashboard-metrics-row">
              <metric-card
                  :metric-number="providerDetails.healthMetrics.weight.numberOfPeopleReduced === 0 ? 'N/A' : `${providerDetails.healthMetrics.weight.numberOfPeopleReduced} people`"
                  :metric-title="`Lost weight`"
                  :tool-tip="`The number of participants that lost weight`"
              />
              <metric-card
                  :metric-number="providerDetails.providerMetrics.completedN === 0 ? 'N/A' : providerDetails.providerMetrics.completedN"
                  :metric-title="`Completion rate`"
                  :tool-tip="`Total number of participants who completed all lessons`"
                  :metric-percent="calcCompletionRate(providerDetails.providerMetrics.completedN, providerDetails.providerMetrics.registeredN)"
                  :hide-icon="true"
              />
            </div>
            </div>
            <div class="medicine">
              <div class="dashboard-metrics-row">
                <div class="medicine-card">
                  <div class="number">
                    <h3>{{ providerDetails.healthMetrics.medication.totalPeopleTakingN }} of {{ referralSummary.redeemed }}</h3>
                    <span>taking medication</span>
                  </div>
                  <div class="common-medicine mt-3">
                    <h4 class="mb-1">Most common</h4>
                    <div class="medicine-table">
                      <div class="medicine-tr d-flex justify-content-between" v-for="(item, index) in providerDetails.healthMetrics.medication.mostCommon">
                        <div class="medicine-td medicine-name">{{ item.medication }}</div>
                        <div class="medicine-td medicine-amount">{{ item.onMedicationN }}</div>
                        <div v-if="index !== providerDetails.healthMetrics.medication.mostCommon.length - 1" class="medicine-divider"></div>
                      </div>
                    </div>
                  </div>
                  <div class="info-icon">
                    <i class="material-icons material-icons-filled" v-tooltip="`Participants currently taking any kind of medication`">info</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-metrics">
          <h2>Referral metrics</h2>
          <div class="dashboard-metrics-cards">
            <div class="dashboard-metrics-row">
              <metric-card
                  :metric-number="referralSummary.issued"
                  :metric-title="`Codes issued`"
                  :tool-tip="`Total number of codes issued`"
              />
              <metric-card
                  :metric-number="referralSummary.redeemed"
                  :metric-title="`Codes redeemed`"
                  :is-blue="true"
                  :tool-tip="`Total number of codes issued`"
              />
              <metric-card
                  :metric-number="referralSummary.mostPopular"
                  :metric-title="`Most popular`"
                  :is-blue="true"
                  :tool-tip="`Most popular referral code redeemed by participants`"
              />
            </div>
          </div>
          <div class="referral-table">
            <gro-table :data="values.codes" v-if="values">
              <thead>
              <tr>
                <th scope="col">
                  <gro-table-sort-item name="voucher">Referral code</gro-table-sort-item>
                </th>
                <th scope="col">
                  <gro-table-sort-item name="maximumUses">Uses per referral code <i class="material-icons material-icons-filled" v-tooltip="`Number of times a code can be redeemed`">info</i></gro-table-sort-item>
                </th>
                <th scope="col">
                  <gro-table-sort-item name="redemptions">Codes redeemed <i class="material-icons material-icons-filled" v-tooltip="`Number of times a code has been redeemed`">info</i></gro-table-sort-item>
                </th>
              </tr>
              </thead>
              <tbody slot="body" slot-scope="sort">
              <tr v-for="value in sort.values" :key="value.id">
                <td class="name">{{ value.voucher }}</td>
                <td>{{ value.maximumUses }}</td>
                <td>{{ value.redemptions }}</td>
              </tr>
              </tbody>
            </gro-table>
            <div class="pagination-wrap" v-if="values.totalPages > 1">
              <gro-paginator
                  :page-count="values.totalPages"
                  :force-page="3"
                  v-model="voucherPage"
                  :margin-pages="2"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :page-class="'page-item'">
              </gro-paginator>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-patients" v-if="patientsList">
        <div class="patients-title-block">
          <div class="patients-title">
            <h2>My patients</h2>
            <search-bar></search-bar>
          </div>
          <router-link to="/patients">See all patients</router-link>
        </div>
        <gro-horizontal-grid class="pt-5 pb-5 pl-3">
          <gro-horizontal-grid-item v-for="(patient, index) in patientsList.patients" :key="`item-${index}`">
            <router-link :to="{name: 'PatientDetails', params: { id: patient.userId }}">
              <gro-card shadow :bordered="false" :borderIndicatorColor="getCardColour(patient.progress)">
                <div slot="body">
                  <div class="mb-2">
                    <div class="text-primary gro__card__info__title">{{ patient.firstName + ' ' + patient.lastName }}</div>
                    <div class="gro__card__label__user_id">User {{patient.userId}}</div>
                    <div class="gro__card__label__diabetes__type">{{ patient.diabetesType }}</div>
                  </div>
                  <div class="divider"></div>
                  <div class="mb-2">
                    <div class="gro__card__label">Baseline A1c</div>
                    <div class="gro__card__label__info">{{ patient.hbA1c.baseline ? patient.hbA1c.baseline.toFixed(1) : patient.hbA1c.baseline }} mmol/mol</div>
                  </div>
                  <div class="mb-2">
                    <div class="gro__card__label">Baseline weight</div>
                    <div class="gro__card__label__info">{{ patient.weight.baseline }}kg</div>
                  </div>
                  <div class="divider"></div>
                  <div class="mb-2">
                    <div class="gro__card__label">Current A1c</div>
                    <div class="gro__card__label__info">
                      {{ patient.hbA1c.current ? patient.hbA1c.current.toFixed(1) : patient.hbA1c.current }}  mmol/mol
                      <div v-if="calcPercentageIcon(patient.hbA1c.current, patient.hbA1c.baseline) !== ''" :class="calcPercentageIcon(patient.hbA1c.current, patient.hbA1c.baseline)">({{calcPercentage(patient.hbA1c.current, patient.hbA1c.baseline)}}%<font-awesome-icon  :icon="calcPercentageIcon(patient.hbA1c.current, patient.hbA1c.baseline)"></font-awesome-icon>)</div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="gro__card__label">Current weight</div>
                    <div class="gro__card__label__info">{{ patient.weight.current }}kg
                      <div v-if="calcPercentageIcon(patient.weight.current, patient.weight.baseline) !== ''" :class="calcPercentageIcon(patient.weight.current, patient.weight.baseline)">({{calcPercentage(patient.weight.current, patient.weight.baseline)}}%<font-awesome-icon  :icon="calcPercentageIcon(patient.weight.current, patient.weight.baseline)"></font-awesome-icon>)</div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div>
                    <div class="gro__card__label">Current progress</div>
                    <div class="gro__card__label__info">{{ patient.progress ? patient.progress : 'N/A' }}%</div>
                  </div>
                  <div v-if="patient.surgeryName">
                    <div class="divider"></div>
                    <div>
                      <div class="gro__card__label">Surgery</div>
                      <div class="gro__card__label__info">{{ patient.surgeryName }}</div>
                    </div>
                  </div>
                </div>
              </gro-card>
            </router-link>
          </gro-horizontal-grid-item>
        </gro-horizontal-grid>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
  import MetricCard from "@/components/MetricCard/MetricCard";
  import SearchBar from "@/components/SearchBar/SearchBar";
  import AnalyticsApi from "@/api/Analytics";
  import axios from "axios";

  export default {
    name: "dashboard",
    components: {
      SearchBar,
      MetricCard
    },
    data() {
      return {
        searchTerm: '',
        values: null,
        voucherPage: 1,
        patientsList: null,
        providerDetails: null,
        dashIsLoading: false,
        referralSummary: null,
        dataFailed: false,
        errMsg: null,
      }
    },
    computed: {
      provider: function () {return this.$store.getters.storedProvider}
    },
    watch: {
      provider: function() {
        this.dashIsLoading = true;
        this.getDashDetails();
      },
      voucherPage: function() {
        this.getCodeNextPage();
      }
    },
    methods: {
      calcPercentageIcon(current, starting) {
        let self = this;
        let checkValue = self.calcPercentage(current, starting);
        if (parseInt(checkValue) === 0 || checkValue.toString() === '') {
          return ''
        }
        if (checkValue.toString().indexOf('-') !== -1) {
          return 'caret-down'
        } else {
          return 'caret-up'
        }
      },
      formatCardData(type, unit) {
        if (type === 0 || type == null) {
          return 'N/A'
        } else {
          return type.toFixed(1) + unit;
        }
      },
      getCardColour: function(progress) {
        if (progress === 100) {
          return '#008a00'
        } else if(progress >= 50) {
          return '#d56f01'
        } else if(progress < 50) {
          return '#dc143c'
        }
      },
      getCodeNextPage() {
        let self = this;
        AnalyticsApi.getProviderCodes(self.$store.getters.storedProvider.id, self.voucherPage)
            .then(function(response) {
              self.values = response.data;
            })
      },
      getDashDetails() {
        let self = this;
        if (self.$store.getters.storedProvider) {
        self.dashIsLoading = true;
          axios.all([AnalyticsApi.getProviderDetails(self.$store.getters.storedProvider.id), AnalyticsApi.getProviderSummary(self.$store.getters.storedProvider.id), AnalyticsApi.getProviderCodes(self.$store.getters.storedProvider.id), AnalyticsApi.getProviderPatients(self.$store.getters.storedProvider.id)])
              .then(axios.spread((firstResponse, secondResponse, thirdResponse, fourthResponse) => {
                self.providerDetails = firstResponse.data;
                self.referralSummary = secondResponse.data;
                self.values = thirdResponse.data;
                self.patientsList = fourthResponse.data;
                self.dataFailed = false;
                self.dashIsLoading = false;
              }))
              .catch(error => {
                self.dashIsLoading = false;
                self.dataFailed = true;
                self.errMsg = error;
              })
        }
      },
      goToPatient(user_id) {
        let self = this;
        self.$router.push({
          name: 'PatientDetails',
          params: {
            id: user_id
          },
        })
      },
      calcPercentage(current, starting, units) {
        let sum = current - starting;
        if (units === undefined) {
          units = '%';
        }
        if (current) {
          if ( sum === 0 ) {
            return ''
          } else {
            return sum.toFixed(1).toString() + units ;
          }
        } else {
          return '';
        }
        // = % out of 100 (positive is gain, negative is reduced)
      },
      calcCompletionRate(completed, issued) {
        if (completed) {
          return ((100 * completed) / issued).toFixed();
        } else {
          return '';
        }
      }
    },
    mounted() {
      this.$store.commit('search_patients', '')
      this.getDashDetails();
    }
  }
</script>

<style scoped lang="scss">
  #dashboard {
    .alert-block {
      .gro__notice {
        width: 350px;
        margin: 0 auto;
      }
    }
    .dashboard-inner {
      max-width: 900px;
      margin: 0 auto;
      h1 {
        font-family: $poppins;
        font-weight: 600;
        color: $theme-color;
        font-size: 2rem;
        margin-bottom: 20px;
      }
      p {
        font-family: $poppins;
        color: $black;
        font-size: 1rem;
        margin: 20px 0;
      }
      .dashboard-metrics {
        h2 {
          color: $theme-color;
          font-family: $poppins;
          font-size: 1.8rem;
          margin: 12px 0;
        }
        .dashboard-metrics-cards {
          &.medicine-block {
            @include flexbox();
            @include responsive-below(700px) {
              display: block;
            }
            .top-metrics {
              width: 70%;
              @include responsive-below(700px) {
                width: 100%;
              }
            }
            .medicine {
              margin-left: 12px;
              .medicine-card {
                position: relative;
                min-height: 90px;
                width: 100%;
                padding: 10px 8px 6px 16px;
                border-radius: 13px;
                border: 1px solid rgba(200, 199, 204, 0.3);
                margin: 0 12px 0 0;
                background: $white;
                overflow: hidden;
                .number {
                  h3 {
                    font-family: $poppins;
                    font-size: 1.8rem;
                    color: $black;
                    font-weight: 700;
                  }
                  span {
                    font-family: $poppins;
                    font-size: 1rem;
                    color: $black;
                    font-weight: 400;
                  }
                }
                .common-medicine {
                  h4 {
                    font-family: $poppins;
                    font-size: 1rem;
                    color: $black;
                    font-weight: 700;
                  }
                  .medicine-table {
                    .medicine-tr {
                      margin: 8px 0;
                      position: relative;
                      .medicine-td {
                        font-family: $poppins;
                        font-size: 1rem;
                        color: $black;
                        font-weight: 400;
                      }
                      .medicine-divider {
                        height: 2px;
                        position: absolute;
                        background: rgba(200, 199, 204, 0.3);
                        bottom: -4px;
                        width: 108%;
                        border-radius: 50%;
                      }
                    }
                  }
                }
                .info-icon {
                  position: absolute;
                  top: 4px;
                  right: 6px;
                  .material-icons {
                    color: $lightGray2;
                    font-size: 20px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .dashboard-metrics-row {
            margin: 15px 0;
            @include flexbox();
            @include flexbox-direction(row);
            &:first-of-type {
              margin-top: 0;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .referral-table {
          margin-top: 15px;
          table {
            width: 100%;
            thead {
              tr {
                th {
                  font-family: $poppins;
                  color: $black;
                  font-size: .9rem;
                  font-weight: 700;
                  padding-top: 15px;
                  padding-bottom: 15px;
                  .material-icons {
                    color: $lightGray2;
                    font-size: 12px;
                    cursor: pointer;
                    margin-left: 4px;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  font-family: $poppins;
                  font-size: 1rem;
                  &.name {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .pagination-wrap {
            padding: 15px 0 2px;
            @include flexbox();
            @include flexbox-align-items(center);
            @include flexbox-justify-content(center);
          }
        }
      }
    }
    .dashboard-patients {
      background: $white;
      padding-top: 25px;
      padding-bottom: 25px;
      margin: 15px 0 0 0;
      padding-left: 120px;
      .patients-title-block {
        @include flexbox();
        @include flexbox-align-items(center);
        @include flexbox-justify-content(space-between);
        a {
          color: $theme-color;
          font-family: $poppins;
          font-weight: normal;
          font-size: 1rem;
          margin-right: 23px;
          text-decoration: none;
        }
        .patients-title {
          @include flexbox();
          @include flexbox-align-items(center);
          h2 {
            color: $theme-color;
            margin-right: 24px;
            font-family: $poppins;
            font-weight: 600;
            font-size: 1.8rem;
          }
          .gro__input {
            width: 400px;
          }
        }
      }
      .gro__horizontal__grid {
        .gro__horizontal__grid__item {
          width: 196px;
          cursor: pointer;
          @include transition();
          &:hover {
            transform: scale(1.06);
          }
          .gro__card__info__title {
            font-family: $poppins;
            font-weight: 700;
            color: $theme-color;
            line-height: 27px;
            margin-bottom: 0;
            word-break: break-word;
          }
          .gro__card__label__user_id {
            font-size: 12px;
            color: #5b617c;
            letter-spacing: -0.3px;
            margin-bottom: 8px;
          }
          .gro__card__label__diabetes__type {
            font-size: 12px;
            font-family: $poppins;
            letter-spacing: -0.3px;
          }
          .gro__card__label {
            font-size: 12px;
            font-family: $poppins;
            margin-bottom: 2px;
          }
          .gro__card__label__info {
            font-size: 1rem;
            font-family: $poppins;
            display: inline;
            .caret-down {
              color: #008000;
              display: inline;
            }
            .caret-up {
              color: #dc143c;
              display: inline;
            }
          }
        }
      }
    }
  }

  .gro__card .gro__card__body .divider {
    border-width: 2px;
  }
</style>