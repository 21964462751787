<template>
  <div class="metric-card">
    <div class="metric-number">
      <h3 :class="isBlue ? 'blueText' : ''">
        {{ metricNumber }}
        <span v-if="metricPercent" class="metric-percent">
          ({{metricPercent}} <font-awesome-icon v-if="checkNumber === true && hideIcon !== true" icon="caret-down"></font-awesome-icon><font-awesome-icon v-if="checkNumber === false && hideIcon !== true" class="caretUp" icon="caret-up"></font-awesome-icon>)
        </span>
        <span v-if="patientChangeArrow" class="metric-percent">
          <font-awesome-icon v-if="checkChange === false" icon="caret-down"></font-awesome-icon><font-awesome-icon v-if="checkChange === true" class="caretUp" icon="caret-up"></font-awesome-icon>
        </span>
      </h3>
    </div>
    <div class="metric-title">
      <p>{{ metricTitle }}</p>
    </div>
    <div class="metric-info-icon">
      <i class="material-icons material-icons-filled" v-tooltip="toolTip">info</i>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MetricCard",
    data() {
      return {
      }
    },
    props: {
      patientChangeArrow: {
        required: false,
      },
      metricNumber: {
        required: true,
      },
      metricTitle: {
        required: true,
      },
      metricPercent: {
        required: false,
      },
      isBlue: {
        required: false,
      },
      toolTip: {
        default: false,
        required: true,
      },
      hideIcon : {
        required: false,
      }
    },
    computed: {
      checkChange: function() {
        if (this.patientChangeArrow) {
          return this.patientChangeArrow.toString().indexOf('-') !== -1;
        } else {
           return '';
        }
      },
      checkNumber: function() {
        if (this.metricPercent) {
          return this.metricPercent.toString().indexOf('-') !== -1;
        } else {
          return '';
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .metric-card {
    position: relative;
    min-height: 90px;
    width: 100%;
    padding: 10px 8px 6px 16px;
    border-radius: 13px;
    border: 1px solid rgba(200, 199, 204, 0.3);
    margin: 0 12px 0 0;
    background: $white;
    &:last-of-type {
      margin: 0;
    }
    .metric-number {
      h3 {
        font-family: $poppins;
        font-size: 1.8rem;
        color: $black;
        font-weight: 700;
        &.blueText {
          color: $theme-color;
        }
        span {
          font-family: $poppins;
          font-size: 1rem;
          color: $black;
          font-weight: 400;
          svg {
            color: #008000;
            font-size: 27px;
          }
          &.metric-percent {
            svg {
              vertical-align: text-bottom;
            }
            .caretUp {
              color: #dc143c;
            }
          }
        }
      }
    }
    .metric-title {
      margin-top: 3px;
      p {
        font-family: $poppins;
        font-size: 1rem;
        color: $black;
      }
    }
    .metric-info-icon {
      position: absolute;
      top: 4px;
      right: 6px;
      .material-icons {
        color: $lightGray2;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
</style>